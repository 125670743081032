import _, { find, map } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LabelledSelect from './LabelledSelect';
import LabelledCheckbox from './LabelledCheckbox';
import LabelledInput from './LabelledInput';
import AutosuggestTagsInput from './AutosuggestTagsInput';

import { getDepartmentOptions, getAddressOptions, getTags } from '../selectors/dropdowns';

import { createUpdateContact, createUpdateContactPhone, createAddContactPhone, createDeleteContactPhone, updateContactTags } from '../actions/contact';

import { oauth, toTitleCase } from '../utils';
import { Link, Text, MarketingStatusIcon } from '@commonsku/styles';
import { window, document } from '../global';

class ContactBriefing extends Component {
  constructor(props) {
    super(props);

    this.state = Object.values(props.contact.phones).reduce((o, phone) => {
      o[`phone_number_${phone.phone_id}`] = phone.phone_number;
      o[`phone_extension_${phone.phone_id}`] = phone.phone_extension;
      return o;
    }, {});
    this.state.contact_email = props.contact.contact_email;
    this.state.contact_department_id = props.contact.contact_department_id;
    this.state.contact_default_address_id = props.contact.contact_default_address_id;
    this.state.contact_no_marketing = props.contact.contact_no_marketing;
    this.state.newTags = props.beforeTags;
    this.state.editContact = props.editContact;
    this.state.syncReplaced = false;

    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleAddPhone = this.handleAddPhone.bind(this);
    this.loadGoogleMap = this.loadGoogleMap.bind(this);
    this.handleReplaceMpOtherContact = this.handleReplaceMpOtherContact.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.loadGoogleMap();
  }

  loadGoogleMap() {
    let full_address = this.state.contact_default_address_id ? this.props.address_details[this.state.contact_default_address_id] : null;
    if(full_address) {
      if(document.getElementById('map_canvas')) {
        document.getElementById('map_canvas').style.display = 'block';
      }
      let address = `${full_address.address_line_1} ${full_address.address_line_2} ${full_address.address_city} ${full_address.address_state} ${full_address.address_country} ${full_address.address_postal}`;

      if (!_.isUndefined(window.google)) {
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({
        'address': address
      }, function(results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          let Lat = results[0].geometry.location.lat();
          let Lng = results[0].geometry.location.lng();
          let myOptions = {
            zoom: 11,
            center: new window.google.maps.LatLng(Lat, Lng)
          };
          let map = new window.google.maps.Map(
            document.getElementById("map_canvas"), myOptions);
          let marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(Lat, Lng),
            map: map,
            title: ''
          });
        } else {
          console.log("Geostatus: " + status);
        }
      });
      }
    }else{
      if(document.getElementById('map_canvas')) {
        document.getElementById('map_canvas').style.display = 'none';
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      contact_email: nextProps.contact.contact_email,
      contact_department_id: nextProps.contact.contact_department_id,
      contact_default_address_id: nextProps.contact.contact_default_address_id,
      editContact: nextProps.editContact,
      contact_no_marketing: nextProps.contact.contact_no_marketing,
    });

    this.loadGoogleMap();
  }

  handleChangeValue(e, field) {
    this.setState({ [field]: e});
  }

  handleAddPhone() {
    this.props.onCreateAddContactPhone(this.props.contact.company_contact_id, this.props.contact.company_id, '', 'WORK', '', '');
  }

  renderAddress() {
    const { address_details } = this.props;
    const { contact_default_address_id } = this.state;
    const address = _.get(address_details, contact_default_address_id);

    if (!address) {
      return null;
    }
    return <div>
      <p style={{marginTop: '1rem', marginBottom: 0}}>
        {address.address_name}
      </p>
      {_.map(
        _.pick(address, [
          'address_line_1', 'address_line_2', 'address_line_3', 'address_line_4',
        ]),
        (v, k) => {
          return v ? <div key={k}>{v}, </div> : null;
        }
      )}
      <div>
        {_.filter(
          _.pick(address, [
            'address_city', 'address_state', 'address_country', 'address_postal',
          ]),
          (v) => {
            return !!v;
          }
        ).join(', ')}
      </div>
    </div>;
  }

  getMpOtherContact() {
    const other_mp = this.props.contact.mp_other_contact;
    if (_.isEmpty(other_mp)) {
      return null;
    }
    other_mp['platform'] = '';
    if (other_mp.mp_data.mailchimp_sync_status) {
      other_mp['platform'] = 'Mailchimp';
    } else if (other_mp.mp_data.hubspot_sync_status) {
      other_mp['platform'] = 'Hubspot';
    }
    other_mp['full_name'] = '';
    if (other_mp.contact_first_name || other_mp.contact_last_name) {
      other_mp['full_name'] = other_mp.contact_first_name + ' ' + other_mp.contact_last_name;
    }
    other_mp['contact_link'] = `/contact/${other_mp.company_contact_id}`;
    return other_mp;
  }

  handleReplaceMpOtherContact(new_contact_id) {
    oauth('POST', 'marketing-platform/', {
      action: 'replace-mp-contact',
      contact_id: new_contact_id,
      new_contact_id: this.props.contact.contact_id,
    }).then(res => {
      this.setState({
        syncReplaced: true,
      });
      this.props.setShowMarketingLink();
    });
  }

  render() {
    const {
      contact, addresses, departments, tags, onCreateUpdateContact, onCreateUpdateContactPhone, onCreateDeleteContactPhone
    } = this.props;
    const other_mp_contact = this.getMpOtherContact();
    const { newTags } = this.state;
    const tagOptions = map(tags, (tag) => {
      return {...tag, key: tag.tag_id};
    });
    return (
      <div className="row">
        <div className="small-12 columns">
          {this.state.editContact ? <>
            {/* {this.props.message ? <Alert type="alert">{this.props.message}</Alert> : ''} */}
            <div>
              <LabelledInput className="small-12" label="Email" type="text"
                value={this.state.contact_email}
                onChange={e => this.handleChangeValue(e, 'contact_email')}
                onBlur={onCreateUpdateContact(contact.company_contact_id, contact.company_id, 'contact_email', contact.contact_email)}
              />

              <LabelledCheckbox className="small-12" label="No Marketing"
                value={this.state.contact_no_marketing}
                onChange={v => {
                  this.handleChangeValue(v, 'contact_no_marketing');
                  onCreateUpdateContact(contact.company_contact_id, contact.company_id, 'contact_no_marketing', contact.contact_no_marketing)(v);
                }}
              />

              <label labelfor="phones">Phones&nbsp;&nbsp;
                <small><a onClick={this.handleAddPhone}>add phone</a></small>
              </label>
              {Object.values(contact.phones).map(p =>
                <div key={p.phone_id}>
                  <a style={{float: 'left', marginRight: '5px'}} onClick={e => {e.preventDefault(); onCreateDeleteContactPhone(contact.company_contact_id, p.phone_id);}}>x </a>
                  <select value={p.phone_type} style={{width: '29%', float: 'left', fontSize: '12px'}}
                    onChange={e => onCreateUpdateContactPhone(contact.company_contact_id, contact.company_id, p.phone_id, e.target.value, p.phone_number, p.phone_extension)}>
                    <option value="WORK">WORK</option>
                    <option value="CELL">CELL</option>
                    <option value="HOME">HOME</option>
                    <option value="FAX">FAX</option>
                  </select>
                  <input type="text" value={this.state[`phone_number_${p.phone_id}`]} style={{width: '45%', float: 'left', fontSize: '12px'}} onChange={e => this.handleChangeValue(e.target.value, `phone_number_${p.phone_id}`)}
                    onBlur={e => onCreateUpdateContactPhone(contact.company_contact_id, contact.company_id, p.phone_id, p.phone_type, e.target.value, p.phone_extension)} />
                  <input type="text" value={this.state[`phone_extension_${p.phone_id}`]} style={{width: '20%', fontSize: '12px'}} onChange={e => this.handleChangeValue(e.target.value, `phone_extension_${p.phone_id}`)}
                    onBlur={e => onCreateUpdateContactPhone(contact.company_contact_id, contact.company_id, p.phone_id, p.phone_type, p.phone_number, e.target.value)} />
                </div>
              )}
              <LabelledSelect className="small-12" label="Department" value={this.state.contact_department_id} options={departments} onChange={onCreateUpdateContact(contact.company_contact_id, contact.company_id, 'contact_department_id', contact.contact_department_id)} />
              <label>Tags</label>
              <AutosuggestTagsInput
                value={map(newTags, (tag) => {
                  return find(tagOptions, { label: tag }) || { label: tag };
                })}
                tags={tagOptions}
                onChange={(value)=>{
                  const newTags = map(value, 'label');
                  this.setState({ newTags });
                  this.props.onUpdateContactTags(
                    contact.company_contact_id, contact.company_id, newTags.join(',')
                  );
                }}
              />
              <LabelledSelect className="small-12" label="Location" value={this.state.contact_default_address_id} options={[{key:'', value: ''}, ...addresses]} onChange={onCreateUpdateContact(contact.company_contact_id, contact.company_id, 'contact_default_address_id', contact.contact_default_address_id)} />
            </div>
          </> :
            <div style={{marginBottom: '10px'}}>
              <label labelfor="email">Email</label>
              <p>{contact.contact_email}</p>

              <label labelfor="email">Marketing</label>
              <p>
                {this.state.contact_no_marketing == 1 ? <MarketingStatusIcon /> : ""}
              </p>

              {!this.state.syncReplaced && other_mp_contact ? <div>
                <Text as="p" style={{background: '#fef3c2', padding: 10}}>
                  Another Contact with this email is being synced with {_.get(other_mp_contact, 'platform', null)}:
                  <Link as="a" href={other_mp_contact.contact_link} target="_blank">
                    {' '} {other_mp_contact.full_name || other_mp_contact.contact_email}
                  </Link>
                </Text>
                <button className="button expanded" onClick={e => this.handleReplaceMpOtherContact(other_mp_contact.contact_id)}>Sync this Contact instead</button>
              </div> : null}

              <label labelfor="phones">Phones</label>
              {Object.values(contact.phones).map(p =>
                <p key={p.phone_id}>{toTitleCase(p.phone_type)}: {p.phone_number} {p.phone_extension != '' ? `ext. ${p.phone_extension}` : null}</p>
              )}
              <p>{contact.contact_deparment}</p>
              <label>Tags</label>
              {map(newTags, tag => <div className="contact-static-tag">{tag}</div>)}
            </div>
          }
          {this.renderAddress()}
          <br />
          <div id="map_canvas" style={{position: 'absolute', width: '25%', height: '300px'}}></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const addresses = getAddressOptions(state, { parent_id: ownProps.contact.company_id });
  const departments = getDepartmentOptions(state);
  const address_details = state.entities.addresses;
  const beforeTags = ownProps.contact.contact_tags;
  const editContact = state.temp['edit-contact'] ? true : false;
  const message = ownProps.contact.message;

  return {
    addresses,
    departments,
    address_details,
    beforeTags,
    tags: getTags(state),
    editContact,
    message
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateUpdateContact: (contact_id, client_id, field, previous_value) => value => {
      dispatch(createUpdateContact(contact_id, client_id, field, previous_value, value));
    },
    onCreateUpdateContactPhone: (contact_id, client_id, phone_id, phone_type, phone_number, phone_extension) => {
      dispatch(createUpdateContactPhone(contact_id, client_id, phone_id, phone_type, phone_number, phone_extension));
    },
    onCreateAddContactPhone: (contact_id, client_id, phone_id, phone_type, phone_number, phone_extension) => {
      dispatch(createAddContactPhone(contact_id, client_id, phone_id, phone_type, phone_number, phone_extension));
    },
    onCreateDeleteContactPhone: (contact_id, phone_id) => {
      dispatch(createDeleteContactPhone(contact_id, phone_id));
    },
    onUpdateContactTags: (contact_id, client_id, contact_tags) => {
      dispatch(updateContactTags(contact_id, client_id, contact_tags));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactBriefing);
